import { Trans } from '@lingui/react/macro';
import { LogIn } from 'lucide-react';
import { memo } from 'react';

import { useNavigate } from '@/hooks/useNavigate';
import { useUser } from '@/hooks/useUser';

import { MessageMarkdown } from '../../../components/MessageMarkdown';

type MembershipLoginProps = {
  login: {
    content: string;
    shouldLogin: boolean;
  };
};

export const MembershipLogin = memo(function MembershipLogin({
  login,
}: MembershipLoginProps) {
  const { navigate } = useNavigate();
  const { isEfitnessAuthenticated } = useUser();

  const { content, shouldLogin } = login;

  return (
    <>
      <MessageMarkdown>{content}</MessageMarkdown>
      {shouldLogin && !isEfitnessAuthenticated() && (
        <button
          type='button'
          onClick={() => navigate('signIn')}
          className='my-3 flex flex-row items-center justify-center gap-2 rounded-lg bg-gray-50/20 px-3 py-2.5 text-secondary-fg shadow drop-shadow transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100'
        >
          <LogIn className='size-5' />
          <Trans>Login</Trans>
        </button>
      )}
    </>
  );
});
