import type { ChatUserType } from '@kanbu/schema';
import type { EfCreateMemberDTO } from '@kanbu/schema/contracts';
import { EFitnessGender } from '@kanbu/schema/enums';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { Trans, useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import { useState } from 'react';
import { z } from 'zod';

import MultipleStepForm from '@/components/multistepForm/MultipleStepForm';
import StepForm from '@/components/multistepForm/StepForm';
import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { ClubSelect } from '../eFitness/ClubSelect';
import { NativeFormSelect } from '../nativeFormSelect/NativeFormSelect';
import { Stack } from '../stack/Stack';

type FormValues = EfCreateMemberDTO & { club: string };

export function MemberRegistration() {
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const { t } = useLingui();
  const { login, pushRoute, popRoute } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  // TODO: This is here as a workaround to show long error message, since @utima/ui does not support long error messages
  //       as they clip into the inputs. This should be removed once the issue is fixed in the library.
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined,
  );

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!"#$%&()*,.:<>?@^{|}]/.test(password);

    if (
      password.length < minLength ||
      !hasUpperCase ||
      !hasNumber ||
      !hasSpecialCharacter
    ) {
      setPasswordError(
        t`Password must contain: Minimum 8 characters, at least one uppercase letter, number and special character`,
      );

      return false;
    }

    setPasswordError(undefined);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      return aiCoreApi.eFitness.members.create({
        ...formState.values,
        clubId: formState.values.club,
        gender: Number(formState.values.gender),
      });
    },
  });

  const handleSubmit = async (formState: TypedFormState<FormValues>) => {
    resetErrors();

    try {
      const { token, user, eFitness } = await mutateAsync(formState);

      login(user as unknown as ChatUserType, token, eFitness);
      pushRoute('newMembership');
    } catch (err) {
      await handleError(err);
    }
  };

  return (
    <Stack title={t`Member registration`} onBack={() => popRoute()}>
      <EfErrorAlert errors={errors} />
      <MultipleStepForm showStepCounter onSubmit={handleSubmit}>
        <StepForm
          zodSchema={z
            .object({
              email: z.string().email(),
              phone: z.string().refine((val: string) => /^\d+$/.test(val), {
                message: t`Number must have 9 digits`,
              }),
            })
            .strict()}
          validateOn='change-submit'
          className='flex flex-col gap-5'
        >
          <ClubSelect defaultValue='895' required name='club' label={t`Club`} />
          <Input name='firstName' label={t`First name`} required />
          <Input required name='lastName' label={t`Last name`} />
          <Input name='email' type='email' required label={t`Email`} />
          <div className='flex flex-row justify-around gap-2'>
            <NativeFormSelect
              defaultValue='+420'
              required
              className='w-1/4'
              name='countryPhoneCode'
              label={t`Prefix`}
            >
              <option value='+420'>+420</option>
              <option value='+421'>+421</option>
              <option value='+385'>+385</option>
            </NativeFormSelect>
            <Input
              className='w-3/4'
              name='cellPhone'
              maxLength={9}
              zodItemSchema={z
                .string()
                .refine((val: string) => /^\d{9}$/.test(val), {
                  message: t`Number must have 9 digits`,
                })}
              required
              label={t`Phone number`}
            />
          </div>
          <Input
            name='password'
            passwordPreview
            type='password'
            min={8}
            validate={val => validatePassword(String(val))}
            required
            label={t`Password`}
          />
          {passwordError && (
            <p className='-bottom-3 -mt-3 text-xs text-danger animate-in fade-in'>
              {passwordError}
            </p>
          )}
          <Button
            size='lg'
            type='submit'
            disabled={isPending}
            loading={isPending}
          >
            <Trans>Next step</Trans>
          </Button>
        </StepForm>

        <StepForm className='flex flex-col gap-5'>
          <NativeFormSelect
            name='gender'
            defaultValue=''
            label={t`Gender`}
            required
          >
            <option value={EFitnessGender.MAN.toString()}>
              <Trans>Male</Trans>
            </option>
            <option value={EFitnessGender.WOMAN.toString()}>
              <Trans>Female</Trans>
            </option>
            <option value={EFitnessGender.OTHER.toString()}>
              <Trans>Other</Trans>
            </option>
          </NativeFormSelect>
          <Input
            label={t`Date of birth`}
            name='birthday'
            type='date'
            required
            max={new Date().toISOString().split('T')[0]}
            zodItemSchema={z.string().refine(
              value => {
                const date = new Date(value);

                return date.getTime() <= Date.now();
              },
              {
                message: t`Invalid date`,
              },
            )}
            /* @ts-expect-error Property 'mask' does not exist on type 'IntrinsicAttributes & InputProps' */
            mask={value => formatUtils.date(value, DateFormat.DateInput)}
          />
          <Input name='street' label={t`Street`} required />
          <Input name='city' label={t`City`} required />
          <Input
            name='postalCode'
            maxLength={5}
            zodItemSchema={z
              .string()
              .refine((val: string) => /^\d{5}$/.test(val), {
                message: t`ZIP code must have 5 digits`,
              })}
            label={t`ZIP code`}
            required
          />
          <Button
            size='lg'
            type='submit'
            disabled={isPending}
            loading={isPending}
          >
            <Trans>Sign in to &#34;My Account&#34;</Trans>
          </Button>
        </StepForm>
      </MultipleStepForm>
    </Stack>
  );
}
