import type { FeedbackType } from '@kanbu/schema';
import { FeedbackCategory, Rating } from '@kanbu/schema/enums';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useCallback, useState } from 'react';

import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { FeedbackButton } from './FeedbackButton';
import { FeedbackFrame } from './FeedbackFrame';
import { FeedbackTextArea } from './FeedbackTextArea';
import { Tooltip } from '../tooltip/Tooltip';

export type FeedbackProps = {
  messageId?: string;
  initialFeedback?: FeedbackType | null;
  note?: string | null;
};

type FeedbackState = {
  rating?: Rating;
  category?: FeedbackCategory | null;
  note?: string | null;
};

export const Feedback = memo(function Feedback({
  messageId,
  initialFeedback,
}: FeedbackProps) {
  const [visible, setVisible] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackState | undefined>({
    rating: initialFeedback?.rating,
    category: initialFeedback?.category,
    note: initialFeedback?.note,
  });

  const setResetConversation = useBoundStore(
    state => state.setResetConversation,
  );

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      message,
      category,
      rating,
    }: {
      message?: string | null;
      category?: FeedbackCategory | null;
      rating: Rating;
    }) => {
      if (!messageId) {
        return;
      }

      return aiCoreApi.feedback.create({
        messageId: messageId,
        note: message,
        rating: rating,
        category: category,
      });
    },
  });

  /**
   * Handle feedback submission and close the feedback form.
   */
  const handleFeedback = useCallback(
    async (
      rating: Rating,
      category?: FeedbackCategory | null,
      message?: string | null,
    ) => {
      try {
        await mutateAsync({ message, category, rating });
        setFeedback(f => ({
          ...f,
          note: message === undefined ? f?.note : message,
          category: category === undefined ? f?.category : category,
          rating,
        }));

        if (rating === Rating.Negative) {
          setResetConversation(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [mutateAsync, setResetConversation],
  );

  if (!messageId) {
    return null;
  }

  return (
    <div className='w-[85%]'>
      <div className='-mt-9 ml-6 flex flex-row justify-end gap-1'>
        <div className='flex'>
          <Tooltip
            delayDuration={0}
            sideOffset={4}
            skipDelayDuration={300}
            title={t`Positive rating`}
          >
            <Button
              size='sm'
              variant='secondary'
              className='inline-flex items-center justify-center !rounded-full bg-foreground text-background'
              onClick={() => {
                handleFeedback(Rating.Positive, null, null);
                setVisible(v => false);
              }}
            >
              {feedback?.rating === Rating.Positive ? (
                <IconThumbUpFilled className='size-5' />
              ) : (
                <IconThumbUp className='size-5' />
              )}
            </Button>
          </Tooltip>
        </div>
        <div className='flex'>
          <Tooltip
            delayDuration={0}
            sideOffset={4}
            skipDelayDuration={300}
            title={t`Negative rating`}
          >
            <Button
              size='sm'
              variant='secondary'
              className='inline-flex items-center justify-center !rounded-full bg-foreground text-background'
              onClick={() => {
                // Send negative feedback
                handleFeedback(Rating.Negative);
                setVisible(v => true);
              }}
            >
              {feedback?.rating === Rating.Negative ? (
                <IconThumbDownFilled className='size-5' />
              ) : (
                <IconThumbDown className='size-5' />
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
          >
            <div className='mt-4 flex flex-col items-end gap-2'>
              <FeedbackFrame onClose={() => setVisible(false)}>
                <p className='mb-4'>
                  <Trans>Feedback</Trans>
                </p>
                <div className='flex flex-wrap justify-stretch gap-2'>
                  <FeedbackButton
                    onClick={() => {
                      handleFeedback(
                        Rating.Negative,
                        FeedbackCategory.IrrelevantInformation,
                      );
                    }}
                    className={
                      feedback?.category ===
                      FeedbackCategory.IrrelevantInformation
                        ? 'bg-primary'
                        : ''
                    }
                  >
                    <Trans>Irrelevant</Trans>
                  </FeedbackButton>
                  <FeedbackButton
                    onClick={() => {
                      handleFeedback(
                        Rating.Negative,
                        FeedbackCategory.IncorrectInformation,
                      );
                    }}
                    className={
                      feedback?.category ===
                      FeedbackCategory.IncorrectInformation
                        ? 'bg-primary'
                        : ''
                    }
                  >
                    <Trans>Incorrect information</Trans>
                  </FeedbackButton>
                  <FeedbackButton
                    onClick={() => {
                      handleFeedback(
                        Rating.Negative,
                        FeedbackCategory.InsufficientDetail,
                      );
                    }}
                    className={
                      feedback?.category === FeedbackCategory.InsufficientDetail
                        ? 'bg-primary'
                        : ''
                    }
                  >
                    <Trans>Insufficient detail</Trans>
                  </FeedbackButton>
                </div>
              </FeedbackFrame>
              <FeedbackFrame onClose={() => setVisible(false)}>
                <div className='flex flex-wrap justify-stretch gap-2'>
                  <FeedbackTextArea
                    placeholder={t`Expected answer or solution...`}
                    initialMinHeight='82px'
                    initialValue={feedback?.note || ''}
                    onSubmit={message => {
                      handleFeedback(
                        Rating.Negative,
                        feedback?.category || FeedbackCategory.Other,
                        message,
                      );
                      setVisible(false);
                    }}
                  />
                </div>
              </FeedbackFrame>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
