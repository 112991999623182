import { Form } from '@utima/ui-informed';
import type { FormProps, FormState, InformedProps } from 'informed';
import type { ReactNode } from 'react';
import type { ZodObject, ZodRawShape } from 'zod';

import { combineFormState } from '@/components/multistepForm/MultipleStepFormContext.utils';

import { useMultipleStepForm } from './MultipleStepFormContext';

interface StepProps extends InformedProps<FormProps> {
  children: ReactNode;
  zodSchema?: ZodObject<ZodRawShape>;
}

function StepForm({ children, onSubmit, ...formProps }: StepProps) {
  const { goToNextStep, formState, setFormState } = useMultipleStepForm();

  // Custom onSubmit handler to inject goToNextStep logic
  const handleSubmit = (localState: FormState) => {
    const state = formState
      ? combineFormState(formState, localState)
      : localState;
    setFormState(state);
    // Call the user's custom onSubmit logic for StepForm
    if (onSubmit) {
      try {
        onSubmit(state!);
        goToNextStep();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Form
      initialValues={formProps?.initialValues || formState?.values}
      onSubmit={handleSubmit}
      {...formProps}
    >
      {children}
    </Form>
  );
}

export default StepForm;
