import { renderMarkdown } from '@kanbu/shared';
import { memo } from 'react';

export type MessageMarkdownProps = {
  children?: string;
};

export const MessageMarkdown = memo(function MessageMarkdown({
  children,
}: MessageMarkdownProps) {
  return (
    <div
      style={{ overflowWrap: 'anywhere' }}
      className='form-factory-chat__message flex break-before-auto flex-col gap-2'
      dangerouslySetInnerHTML={{
        __html: renderMarkdown(children),
      }}
    />
  );
});
