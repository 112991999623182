import { Trans } from '@lingui/react/macro';
import { AlertDialog, Button } from '@utima/ui';
import { useCallback, useState, type MouseEvent } from 'react';

type ConfirmDialogProps = {
  onConfirm?: () => Promise<unknown>;
  onCancel?: () => Promise<unknown>;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export function ConfirmDialog({
  open,
  onOpenChange,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) {
  const [confirming, setConfirming] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const handleConfirm = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      try {
        setConfirming(true);
        await onConfirm?.();
      } finally {
        onOpenChange(false);
        setConfirming(false);
      }
    },
    [onConfirm, onOpenChange],
  );

  const handleCancel = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        setCanceling(true);
        await onCancel?.();
        onOpenChange(false);
      } finally {
        setCanceling(false);
      }
    },
    [onCancel, onOpenChange],
  );

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Content className='w-4/5'>
        <AlertDialog.Header>
          <AlertDialog.Title>
            <Trans>Membership confirmation</Trans>
          </AlertDialog.Title>
          <AlertDialog.Description>
            <Trans>Are you sure you want to create a binding membership?</Trans>
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel asChild>
            <Button
              onClick={handleCancel}
              loading={canceling}
              disabled={canceling || confirming}
            >
              <Trans>No</Trans>
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button
              onClick={handleConfirm}
              loading={confirming}
              disabled={canceling || confirming}
            >
              <Trans>Yes</Trans>
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
