import { Trans } from '@lingui/react/macro';
import { Button, cn } from '@utima/ui';
import { useRef, type ComponentPropsWithoutRef, memo, useState } from 'react';

interface FeedbackTextAreaProps
  extends Omit<ComponentPropsWithoutRef<'textarea'>, 'onSubmit'> {
  initialMinHeight?: string;
  initialValue?: string;
  onSubmit: (text: string) => void;
}

export const FeedbackTextArea = memo(function FeedbackTextArea({
  onSubmit,
  placeholder,
  initialMinHeight,
  className,
  initialValue,
  ...restProps
}: FeedbackTextAreaProps) {
  const [text, setText] = useState<string>(initialValue || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    setText(textarea.value);
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSubmit = () => {
    onSubmit(text);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={cn('flex w-full flex-col items-end gap-2', className)}
    >
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleChange}
        placeholder={placeholder}
        className='w-full resize-none overflow-hidden rounded-md border border-gray-300 bg-white p-3 text-sm text-black placeholder:text-opacity-50 focus:outline-none'
        style={{ minHeight: initialMinHeight || '50px' }}
        {...restProps}
      />
      <Button
        type='button'
        onClick={handleSubmit}
        disabled={!text}
        size='sm'
        className='px-6 font-normal'
      >
        <Trans>Send</Trans>
      </Button>
    </form>
  );
});
