import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { Select as InformedSelect } from 'informed';
import { ChevronDown } from 'lucide-react';
import type { ComponentPropsWithoutRef } from 'react';

export interface NativeFormSelectProps
  extends ComponentPropsWithoutRef<typeof InformedSelect> {}

/**
 * Custom styles for native select component to match utima UI
 */
export function NativeFormSelect({
  className,
  children,
  ...restProps
}: NativeFormSelectProps) {
  return (
    <div className='relative [&_label]:mb-2 [&_label]:block [&_label]:text-sm [&_label]:font-medium [&_label]:text-foreground [&_small]:text-xs [&_small]:text-danger'>
      <InformedSelect
        className={cn(
          'relative flex h-9 px-3 py-2 w-full min-w-full appearance-none rounded-radius bg-foreground text-sm text-background outline-none transition-all focus:bg-foreground disabled:cursor-not-allowed disabled:opacity-75',
          className,
        )}
        {...restProps}
      >
        <option disabled selected value=''>
          <Trans>Select</Trans>
        </option>
        {children}
      </InformedSelect>
      <div className='pointer-events-none absolute right-3 top-[38px] text-background'>
        <ChevronDown className='size-4' />
      </div>
    </div>
  );
}
