import type { Club } from '@kanbu/schema';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import type { Select as InformedSelect } from 'informed';
import type { ComponentPropsWithoutRef } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';

import { NativeFormSelect } from '../nativeFormSelect/NativeFormSelect';

export interface ClubSelectProps
  extends Omit<ComponentPropsWithoutRef<typeof InformedSelect>, 'children'> {}

export function ClubSelect({ className, ...restProps }: ClubSelectProps) {
  const { chatId } = useChatConfig();

  const { data: clubs = [], isLoading } = useQuery({
    queryKey: ['activeClubs'],
    queryFn: () =>
      aiCoreApi.eFitness.clubs.findAll({
        chatId: chatId,
      }),
  });

  // Group clubs by city
  const groupedClubs = Object.groupBy(clubs, club => club.city) as Record<
    string,
    Club[]
  >;

  if (!chatId) {
    throw new Error('chatId is required');
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <NativeFormSelect
      className={cn(
        'relative flex h-9 px-3 py-2 w-full min-w-full appearance-none rounded-radius bg-foreground text-sm text-background outline-none transition-all focus:bg-foreground disabled:cursor-not-allowed disabled:opacity-75',
        className,
      )}
      {...restProps}
    >
      {Object.entries(groupedClubs).map(([city, cityClubs]) => (
        <optgroup key={city} label={city}>
          {cityClubs.map(club => (
            <option key={club.id} value={club.clubId}>
              {club.name}
            </option>
          ))}
        </optgroup>
      ))}
    </NativeFormSelect>
  );
}
