import { efAuthSchema, withClubId } from '@kanbu/schema/contracts';
import { Trans, useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Form, Input, type TypedFormState } from '@utima/ui-informed';
import { memo } from 'react';
import type { z } from 'zod';

import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { ClubSelect } from '../eFitness/ClubSelect';
import { Stack } from '../stack/Stack';

const signInSchema = withClubId(efAuthSchema);
type SignInFormValues = z.infer<typeof signInSchema>;

export const SignIn = memo(function SignIn() {
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const { t } = useLingui();
  const [pushRoute, login] = useBoundStore(state => [
    state.pushRoute,
    state.login,
  ]);

  const routeToChat = () => {
    pushRoute('chat');
  };

  const signInMutation = useMutation({
    mutationFn: (values: SignInFormValues) => {
      return aiCoreApi.eFitness.auth.login({
        clubId: values.clubId,
        login: values.login,
        password: values.password,
      });
    },
  });

  const handleSubmit = async ({ values }: TypedFormState<SignInFormValues>) => {
    resetErrors();

    try {
      const { user, token, eFitness } =
        await signInMutation.mutateAsync(values);

      login(user, token, eFitness);
      routeToChat();
    } catch (error) {
      await handleError(error);
    }
  };

  return (
    <Stack onBack={routeToChat} title={t`Login`}>
      <Form<SignInFormValues>
        zodSchema={signInSchema as never}
        className='flex h-full flex-col justify-center'
        onSubmit={handleSubmit}
      >
        <EfErrorAlert errors={errors} />
        <div className='flex flex-col gap-4'>
          <ClubSelect
            defaultValue='895'
            required
            name='clubId'
            label={t`Clubs`}
          />
          <Input required name='login' type='text' label={t`Email`} />
          <Input
            required
            name='password'
            type='password'
            passwordPreview
            label={t`Password`}
          />
          <Button
            type='submit'
            size='lg'
            disabled={signInMutation.isPending}
            loading={signInMutation.isPending}
          >
            <Trans>Sign in</Trans>
          </Button>
        </div>
      </Form>
    </Stack>
  );
});
