/**
 * Enum for error codes. These codes are used to identify specific errors
 * and are used for localization on FE.
 */
export enum ErrorCodes {
  // General exceptions
  INTERNAL_SERVER_ERROR = 'internalServerError',

  // Service/entity exceptions
  ENTITY_NOT_FOUND = 'entityNotFound',
  INVALID_REQUEST = 'invalidRequest',
  CONFLICT = 'conflict',
  INVALID_DATA = 'invalidData',
  INVALID_ARGUMENT = 'invalidArgument',
  NOT_IMPLEMENTED = 'notImplemented',

  // Auth exceptions
  AUTH_MISSING_TOKEN = 'authMissingToken',
  AUTH_INVALID_TOKEN = 'authInvalidToken',
  AUTH_INVALID_CHAT_USER = 'authInvalidChatUser',
  AUTH_INSUFFICIENT_PERMISSIONS = 'authInsufficientPermissions',

  // Form Factory exceptions
  FORM_FACTORY_ERROR = 'formFactoryError',

  // Usage limits
  USAGE_LIMITS_EXCEEDED = 'usageLimitsExceeded',

  // Streaming exceptions
  INVALID_TOOL_CALL_CHUNK = 'invalidToolCallChunk',

  // tRPC exceptions
  TRPC_ERROR = 'trpcError',

  // Fastify exceptions
  FASTIFY_ERROR = 'fastifyError',

  // Return in case of other errors
  OTHER_ERROR = 'otherError',
}
