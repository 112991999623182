import { useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { FormTranslationsContext } from '@utima/ui-informed';
import { memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';

import avatarImg from '@/assets/images/avatar.jpg';
import { SignIn } from '@/components/signIn/SignIn';
import { useUser } from '@/hooks/useUser';
import { useBoundStore } from '@/store/store';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { MemberRegistration } from './components/registration/MemberRegistration';
import { NewMembership } from './components/registration/NewMembership';
import { NewMembershipConsent } from './components/registration/NewMembershipConsent';
import { NewMemberShipRecap } from './components/registration/NewMembershipRecap';
import { PauseMembership } from './components/registration/PauseMembership';
import { PaymentMembership } from './components/registration/PaymentMembership';
import { Route } from './components/route/Route';
import { Threads } from './components/threads/Threads';
import { Welcome } from './components/welcome/Welcome';
import { useChatConfig } from './contexts/ChatConfigProvider';
import { useEfitnessRefreshToken } from './hooks/useEfitnessRefreshToken';
import { hasPaymentParam } from './lib/utils';
import { aiCoreApi } from './services/aiCoreClient';

export const Widget = memo(function Widget() {
  const { t } = useLingui();
  const { isAuthenticated } = useUser();
  const [verified, setVerified] = useState(!isAuthenticated);
  const { chatId } = useChatConfig();
  const { open, setUnread, pushRoute, user } = useBoundStore(state => ({
    open: state.open,
    setUnread: state.setUnread,
    pushRoute: state.pushRoute,
    user: state.user,
  }));

  useEffect(() => {
    if (user) {
      pushRoute('chat');
    } else {
      pushRoute('welcome');
    }
  }, [pushRoute, user]);

  // Subscribe to eFitness refresh token
  useEfitnessRefreshToken();

  const formTranslations = useMemo(
    () => ({
      labels: {
        optional: t`Optional field`,
      },
      errors: {
        required: t`Required field`,
      },
    }),
    [t],
  );

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  const { mutateAsync: verify } = useMutation({
    mutationFn: async (chatId: string) => {
      try {
        const result = await aiCoreApi.auth.verify(
          { chatId },
          {
            retry: 0,
          },
        );

        return result.valid;
      } finally {
        setVerified(true);
      }
    },
  });

  // We want to verify the chat user on mount
  useEffect(() => {
    if (hasPaymentParam()) {
      open();
      pushRoute('chat');
    }
  }, [open, pushRoute]);

  // Use useRef to track if verification was already attempted
  const verificationAttempted = useRef(false);

  // We want to verify the chat user on mount
  useEffect(() => {
    if (!isAuthenticated || verificationAttempted.current) {
      return;
    }

    verificationAttempted.current = true;
    verify(chatId);
  }, []);

  if (!verified) {
    return null;
  }

  return (
    <FormTranslationsContext.Provider value={formTranslations}>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
        <Route name='newMembership'>
          <NewMembership />
        </Route>
        <Route name='newMembershipConsent'>
          <NewMembershipConsent />
        </Route>
        <Route name='newMembershipRecap'>
          <NewMemberShipRecap />
        </Route>
        <Route name='membershipPayment'>
          <PaymentMembership />
        </Route>
        <Route name='memberRegistration'>
          <MemberRegistration />
        </Route>
        <Route name='signIn'>
          <SignIn />
        </Route>
        <Route name='threads'>
          <Threads />
        </Route>
        <Route name='pauseMembership'>
          <PauseMembership />
        </Route>
      </ChatWindow>

      <Bubble src={avatarImg} onClick={handleOpen} />
    </FormTranslationsContext.Provider>
  );
});
