import { i18n } from '@lingui/core';
import {
  detect,
  fromHtmlTag,
  fromPath,
  fromSubdomain,
  fromNavigator,
} from '@lingui/detect-locale';

export const defaultLocale = 'cs';

export const supportedUILocales = ['en', 'cs', 'hr', 'sk'];
export type SupportedUILocale = (typeof supportedUILocales)[number];

let detectedLocale: string = defaultLocale;

export function getDetectedLocale() {
  return defaultLocale;
}

/**
 * We detect the locale from the html tag and load the corresponding messages.
 */
export async function dynamicActivate() {
  detectedLocale =
    detect(
      fromPath(0),
      fromSubdomain(0),
      fromHtmlTag('lang'),
      fromNavigator(),
    ) ?? defaultLocale;

  // If the detected locale is not supported, use the default locale
  if (!supportedUILocales.includes(detectedLocale)) {
    console.warn(`Unsupported locale detected: ${detectedLocale}`);
    detectedLocale = defaultLocale;
  }

  try {
    // Try to load the locale
    const { messages } = await import(`./locales/${detectedLocale}.po`);

    i18n.load(detectedLocale, messages);
    i18n.activate(detectedLocale);
  } catch (error) {
    console.error(`Failed to load locale: ${detectedLocale}`, error);

    // Fallback to default locale if not already trying to load it
    if (detectedLocale !== defaultLocale) {
      const { messages } = await import(`./locales/${defaultLocale}.po`);

      i18n.load(defaultLocale, messages);
      i18n.activate(defaultLocale);
    }
  }
}
