import type { StructuredToolCall } from '@kanbu/schema';
import type { RagResultToolOperator } from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { Mail, Phone, User } from 'lucide-react';
import { memo } from 'react';
import type { PartialDeep } from 'type-fest';

import { MessageContainer } from '../components/MessageContainer';
import { MessageMarkdown } from '../components/MessageMarkdown';

export type OperatorResultToolProps = {
  toolCall: StructuredToolCall<PartialDeep<RagResultToolOperator>>;
};

export const OperatorResultTool = memo(function OperatorResultTool({
  toolCall,
}: OperatorResultToolProps) {
  const { content, operator } = toolCall.args;

  return (
    <>
      <MessageContainer className='flex-col gap-2'>
        <MessageMarkdown>{content ?? ''}</MessageMarkdown>
      </MessageContainer>
      {(operator?.name || operator?.email || operator?.phone) && (
        <MessageContainer className='flex-col gap-2 rounded-2xl rounded-tl-none'>
          <h4 className='text-sm font-bold'>
            <Trans>Operator Contact Information</Trans>
          </h4>
          <ul className='flex flex-col gap-1'>
            {operator.name && (
              <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
                <div className='inline-flex items-center gap-2'>
                  <User className='relative top-0.5 size-4 shrink-0' />
                  <span className='break-all'>{operator.name}</span>
                </div>
              </li>
            )}
            {operator.email && (
              <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
                <div className='inline-flex items-center gap-2'>
                  <Mail className='relative top-0.5 size-4 shrink-0' />
                  <span className='break-all'>{operator.email}</span>
                </div>
              </li>
            )}
            {operator.phone && (
              <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
                <div className='inline-flex items-center gap-2'>
                  <Phone className='relative top-0.5 size-4 shrink-0' />
                  <span className='break-all'>{operator.phone}</span>
                </div>
              </li>
            )}
          </ul>
        </MessageContainer>
      )}
    </>
  );
});
