import type { StateCreator } from 'zustand';

export interface AppSlice {
  opened: boolean;
  unread: number;
  popupClosed: boolean;
  resetConversation: boolean;
  open: () => void;
  close: () => void;
  setUnread: (unread: number) => void;
  setPopupClosed: (popUpClosed: boolean) => void;
  setResetConversation: (resetConversation: boolean) => void;
}

export const createAppSlice: StateCreator<AppSlice> = set => ({
  opened: false,
  unread: 0,
  popupClosed: false,
  resetConversation: false,
  open: () => set({ opened: true }),
  close: () => set({ opened: false }),
  setUnread: (unread: number) => set({ unread }),
  setPopupClosed: (popUpClosed: boolean) => set({ popupClosed: popUpClosed }),
  setResetConversation: (resetConversation: boolean) =>
    set({ resetConversation }),
});
