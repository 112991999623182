import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { BadgePlus, Loader2, Send, Square, UserRoundPlus } from 'lucide-react';
import {
  memo,
  useCallback,
  useDeferredValue,
  type ChangeEvent,
  type MouseEvent,
} from 'react';

import { useBoundStore } from '@/store/store';

import { CharacterCounter } from '../characterCounter/CharacterCounter';

type InputBoxProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onAbort?: () => void;
  value: string;
  loading?: boolean;
  streaming?: boolean;
  initializing?: boolean;
  maxLength?: number;
  disclaimerContact?: string;
};

export const InputBox = memo(function InputBox({
  onChange,
  onAbort,
  value,
  loading,
  streaming,
  initializing,
  maxLength = 300,
  disclaimerContact,
}: InputBoxProps) {
  const deferredValue = useDeferredValue(value);
  const [setIsInFAQLoop] = useBoundStore(state => [state.setIsInFAQLoop]);
  const [user, pushRoute] = useBoundStore(state => [
    state.user,
    state.pushRoute,
  ]);

  /**
   * Handle the submission of the input box.
   * If the input box is loading, it will abort the submission.
   */
  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // Don't do anything if there is no onAbort function
      if (!onAbort) {
        return;
      }

      // Prevent submission and abort
      if (loading || streaming) {
        event.preventDefault();
        onAbort();
      }

      setIsInFAQLoop(false);
    },
    [onAbort, loading, streaming, setIsInFAQLoop],
  );

  return (
    <div className='flex flex-col gap-2'>
      <div className='relative'>
        <input
          type='text'
          className='relative flex h-12 w-full min-w-full appearance-none rounded-full bg-background pl-5 pr-20 text-base text-foreground outline-none transition-all focus:bg-background disabled:cursor-not-allowed disabled:opacity-75'
          placeholder={
            loading
              ? t`Waiting for a response...`
              : initializing
                ? t`Loading conversation...`
                : t`Type your question`
          }
          maxLength={maxLength}
          value={value}
          disabled={loading || initializing}
          onChange={onChange}
        />
        <div className='absolute inset-y-0 right-0 flex items-center pr-1'>
          <button
            type='submit'
            className='flex size-10 cursor-pointer items-center justify-center rounded-full bg-primary text-primary-fg transition-all hover:opacity-75 enabled:hover:scale-110 enabled:active:scale-100 disabled:cursor-auto disabled:opacity-75'
            disabled={
              ((loading || streaming) && !onAbort) ||
              initializing ||
              (deferredValue.length < 1 && !loading && !streaming)
            }
            onClick={handleSubmit}
          >
            {initializing && !loading && !streaming ? (
              <Loader2 className='size-5 animate-spin' />
            ) : (loading || streaming) && onAbort ? (
              <Square className='size-5' />
            ) : (
              <Send className='size-5' />
            )}
          </button>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <button
          type='button'
          className='flex shrink-0 items-center gap-1 rounded-md bg-success/20 px-1.5 py-1 text-xs text-success transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
          onClick={() =>
            pushRoute(user?.externalId ? 'newMembership' : 'memberRegistration')
          }
        >
          {user?.externalId ? (
            <>
              <BadgePlus className='size-4' />
              <Trans>New membership</Trans>
            </>
          ) : (
            <>
              <UserRoundPlus className='size-4 text-success' />
              <Trans>Get membership</Trans>
            </>
          )}
        </button>
        <div className='w-full text-center text-xs text-foreground'>
          <Trans>Content is generated using AI.</Trans>
          {disclaimerContact && (
            <Trans>
              This is a test run and I cannot guarantee the accuracy of the
              answers yet. Please send your comments and questions to{' '}
              {disclaimerContact}
            </Trans>
          )}
        </div>
        <CharacterCounter max={maxLength} current={deferredValue?.length} />
      </div>
    </div>
  );
});
