import { formatUtils } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { Avatar, Separator } from '@utima/ui';
import { Minus, MessagesSquare, CircleHelp } from 'lucide-react';
import { memo } from 'react';

import { ResetConversation } from '@/components/resetConversation/ResetConversation';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useProvider } from '@/hooks/useProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';
import { SignInButton } from '../signIn/SignInButton';

export const TopBar = memo(function TopBar() {
  const { themeConfig } = useChatConfig();
  const { logo } = useProvider();
  const [user, close, setUnread, pushRoute, logout] = useBoundStore(state => [
    state.user,
    state.close,
    state.setUnread,
    state.pushRoute,
    state.logout,
  ]);
  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  const handleThreads = () => {
    pushRoute('threads');
  };

  return (
    <div className='sticky top-0 flex h-14 flex-row items-center justify-between bg-primary px-4'>
      <div className='mr-2 flex items-center gap-3'>
        <Image className='h-5' src={themeConfig.logo ?? logo} />
      </div>
      <div className='flex gap-3.5 sm:gap-4'>
        {user?.externalId ? (
          <button
            type='button'
            className='flex items-center gap-2 text-sm text-secondary-fg transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
            onClick={() => logout()}
          >
            <Trans>Logout</Trans>
            <Avatar
              size='xs'
              fallback={formatUtils.initials(formatUtils.fullName(user))}
            />
          </button>
        ) : (
          <SignInButton onSubmit={() => pushRoute('signIn')} />
        )}
        <Separator orientation='vertical' className='h-6 opacity-30' />
        {/*<Tooltip.Provider>*/}
        {/*  <Tooltip.Root>*/}
        {/*    <Tooltip.Trigger>*/}
        <a
          href='https://storage.googleapis.com/form-factory-prod-storage/ff_cz_hints.pdf'
          target='_blank'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          rel='noreferrer'
        >
          <CircleHelp className='size-6' strokeWidth={1.5} />
        </a>
        {/*    </Tooltip.Trigger>*/}
        {/*    <Tooltip.Portal container={getPortalContainer()}>*/}
        {/*      <Tooltip.Content*/}
        {/*        className='z-50 overflow-hidden rounded-radius border border-popover-border bg-popover px-3 py-1.5 text-sm text-popover-fg shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'*/}
        {/*        sideOffset={5}*/}
        {/*      >*/}
        {/*        <p className='max-w-[200px]'>*/}
        {/*          <Trans>Help</Trans>*/}
        {/*        </p>*/}
        {/*      </Tooltip.Content>*/}
        {/*    </Tooltip.Portal>*/}
        {/*  </Tooltip.Root>*/}
        {/*</Tooltip.Provider>*/}
        {/*<Tooltip.Provider>*/}
        {/*  <Tooltip.Root>*/}
        {/*    <Tooltip.Trigger>*/}
        <button
          type='button'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          onClick={handleThreads}
        >
          <MessagesSquare className='size-6' strokeWidth={1.5} />
        </button>
        {/*    </Tooltip.Trigger>*/}
        {/*    <Tooltip.Portal container={getPortalContainer()}>*/}
        {/*      <Tooltip.Content*/}
        {/*        className='z-50 overflow-hidden rounded-radius border border-popover-border bg-popover px-3 py-1.5 text-sm text-popover-fg shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'*/}
        {/*        sideOffset={5}*/}
        {/*      >*/}
        {/*        <p className='max-w-[200px]'>*/}
        {/*          <Trans>Previous conversations</Trans>*/}
        {/*        </p>*/}
        {/*      </Tooltip.Content>*/}
        {/*    </Tooltip.Portal>*/}
        {/*  </Tooltip.Root>*/}
        {/*</Tooltip.Provider>*/}
        {/* FIXME MISSING ON RESET */}
        <ResetConversation />
        <button
          type='button'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          onClick={handleClose}
        >
          <Minus className='size-6' />
        </button>
      </div>
    </div>
  );
});
