import { DateFormat, formatUtils } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { Mail, Phone } from 'lucide-react';
import { memo } from 'react';

import { MessageMarkdown } from '../../../components/MessageMarkdown';

type UserProfileProps = {
  profile: {
    content: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    gender?: string;
    birthDate?: string;
    address?: string;
    isEmailVerified: boolean;
  };
};

export const UserProfile = memo(function UserProfile({
  profile,
}: UserProfileProps) {
  const {
    content,
    firstName,
    lastName,
    email,
    phone,
    gender,
    birthDate,
    address,
    isEmailVerified,
  } = profile ?? {};

  return (
    <>
      <div className='mb-4 overflow-hidden rounded-lg bg-gradient-to-br from-gray-800 to-gray-900 shadow-lg drop-shadow-md duration-500 animate-in fade-in-0 zoom-in-95'>
        <div className='p-4'>
          <div className='flex items-center gap-4'>
            <div className='flex-1'>
              <h4 className='text-lg font-bold text-white'>
                {firstName} {lastName}
                <span
                  className={`ml-1 rounded-full px-1.5 py-0.5 text-xs ${isEmailVerified ? 'bg-success/20 text-success' : 'bg-danger/20 text-danger'}`}
                >
                  {isEmailVerified ? (
                    <Trans>Verified</Trans>
                  ) : (
                    <Trans>Not verified</Trans>
                  )}
                </span>
              </h4>
              <div className='mt-1 flex flex-col gap-2 text-sm text-gray-300'>
                {email && (
                  <div className='flex items-center gap-1'>
                    <Mail className='size-3' />
                    <span>{email}</span>
                  </div>
                )}
                {phone && (
                  <div className='flex items-center gap-1'>
                    <Phone className='size-3' />
                    <span>{phone}</span>
                  </div>
                )}
              </div>
              {(gender || birthDate || address) && (
                <div className='mt-2 text-sm text-gray-400'>
                  {birthDate && (
                    <div>
                      <Trans>
                        Date of birth
                        {formatUtils.date(birthDate, DateFormat.DateNumeric)}
                      </Trans>
                    </div>
                  )}
                  {address && <div>{address}</div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MessageMarkdown>{content}</MessageMarkdown>
    </>
  );
});
