import type { StructuredToolCall } from '@kanbu/schema';
import type { MembershipToolDef } from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { memo } from 'react';
import type { PartialDeep } from 'type-fest';

import { MembershipLogin } from './components/MembershipLogin';
import { MembershipRegistration } from './components/MembershipRegistration';
import { MembershipStatus } from './components/MembershipStatus';
import { UserProfile } from './components/UserProfile';
import { MessageContainer } from '../../components/MessageContainer';
import { MessageMarkdown } from '../../components/MessageMarkdown';

export type MembershipToolProps = {
  toolCall: StructuredToolCall<PartialDeep<MembershipToolDef>>;
  isFinished?: boolean;
};

export const MembershipTool = memo(function MembershipTool({
  toolCall,
  isFinished,
}: MembershipToolProps) {
  // Return early if we don't have args or type yet
  if (!toolCall.args?.type) {
    return null;
  }

  const args = toolCall.args as MembershipToolDef;

  if (!args.content && isFinished) {
    return (
      <MessageContainer>
        <Trans>
          I am sorry, but I have not understood your question. Could you repeat
          it please?
        </Trans>
      </MessageContainer>
    );
  }

  return (
    <MessageContainer className='flex-col'>
      {args.type === 'membership_status' && args.membership_status && (
        <MembershipStatus
          status={{ ...args.membership_status, content: args.content }}
        />
      )}

      {args.type === 'user_profile' && args.user_profile && (
        <UserProfile
          profile={{ ...args.user_profile, content: args.content }}
        />
      )}

      {args.type === 'membership_registration' &&
        args.membership_registration && (
          <MembershipRegistration
            registration={{
              ...args.membership_registration,
              content: args.content,
            }}
          />
        )}

      {args.type === 'membership_login' && args.membership_login && (
        <MembershipLogin
          login={{ ...args.membership_login, content: args.content }}
        />
      )}

      {args.type === 'other' && args.content && (
        <MessageMarkdown>{args.content}</MessageMarkdown>
      )}
    </MessageContainer>
  );
});
