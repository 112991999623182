import { useState, useEffect } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';

/**
 * Maps theme property names to CSS variable names (must match the function in ChatConfigProvider)
 */
function themePropToCssVar(propName: string): string {
  // Special case mappings
  const specialCases: Record<string, string> = {
    primaryFg: 'primary-fg',
    secondaryFg: 'secondary-fg',
    backgroundSecondary: 'background-secondary',
  };

  // Check if it's a special case
  if (propName in specialCases) {
    return `--form-factory-${specialCases[propName]}`;
  }

  // Default case: convert camelCase to kebab-case
  return `--form-factory-${propName.replaceAll(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`;
}

/**
 * A debugging component that displays the current theme variables
 * Only used in development mode
 */
export function ThemeDebugger() {
  const { themeConfig } = useChatConfig();
  const [isOpen, setIsOpen] = useState(false);
  const [cssVars, setCssVars] = useState<Record<string, string>>({});
  const [mismatches, setMismatches] = useState<
    Array<{
      prop: string;
      configValue: string;
      cssValue: string;
      cssVarName: string;
    }>
  >([]);

  // Get all CSS variables on mount and when theme changes
  useEffect(() => {
    const rootElement = document.querySelector('.form-factory-chatbot-app');
    if (!rootElement) return;

    // Get computed styles
    const computedStyles = getComputedStyle(rootElement);
    const themeVars: Record<string, string> = {};

    // Get only form-factory CSS variables
    for (let i = 0; i < computedStyles.length; i++) {
      const prop = computedStyles[i];
      if (prop.startsWith('--form-factory-') || prop.startsWith('--kanbu-')) {
        themeVars[prop] = computedStyles.getPropertyValue(prop).trim();
      }
    }

    setCssVars(themeVars);

    // Find mismatches between theme config and CSS variables
    const themeConfigMismatches = [];
    for (const [key, value] of Object.entries(themeConfig)) {
      if (typeof value === 'string' && value.startsWith('#')) {
        const cssVarName = themePropToCssVar(key);
        const cssValue = themeVars[cssVarName]?.trim();

        if (cssValue && cssValue !== value) {
          themeConfigMismatches.push({
            prop: key,
            configValue: value,
            cssValue,
            cssVarName,
          });
        }
      }
    }

    setMismatches(themeConfigMismatches);
  }, [themeConfig]);

  return (
    <div className='fixed bottom-0 left-20 z-[9999999] rounded-md bg-background shadow-lg'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='flex w-full items-center justify-center rounded-t-md bg-primary p-2 text-primary-fg'
      >
        {isOpen ? 'Hide Theme Debug' : 'Show Theme Debug'}
      </button>

      {isOpen && (
        <div className='max-h-[400px] overflow-y-auto p-4 text-sm'>
          <div className='bg-primary/10 mb-4 rounded-md p-3'>
            <h3 className='mb-2 font-bold'>
              Current Theme:{' '}
              {themeConfig.primary === '#22222A' ? 'Dark' : 'Light'}
            </h3>
            <p className='text-xs'>
              Theme variables are applied using the --form-factory-* prefix.
              Dark theme has #22222A primary color, Light theme has #000000
              primary color.
            </p>
          </div>

          {mismatches.length > 0 && (
            <>
              <h3 className='mb-2 font-bold text-red-500'>
                ⚠️ Theme Mismatches
              </h3>
              <div className='mb-4 grid gap-2 rounded-md bg-red-100 p-2 dark:bg-red-900/20'>
                {mismatches.map(
                  ({ prop, configValue, cssValue, cssVarName }) => (
                    <div
                      key={prop}
                      className='grid grid-cols-[1fr,auto,auto] items-center gap-2'
                    >
                      <div>
                        <span className='font-mono text-xs font-bold'>
                          {prop}
                        </span>
                        <span className='ml-2 font-mono text-xs opacity-60'>
                          ({cssVarName})
                        </span>
                      </div>
                      <div className='flex items-center gap-2'>
                        <div
                          className='size-4 rounded-sm border border-gray-300'
                          style={{ backgroundColor: configValue }}
                        />
                        <span className='font-mono text-xs'>{configValue}</span>
                      </div>
                      <div className='flex items-center gap-2'>
                        <div
                          className='size-4 rounded-sm border border-gray-300'
                          style={{ backgroundColor: cssValue }}
                        />
                        <span className='font-mono text-xs'>{cssValue}</span>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </>
          )}

          <h3 className='mb-2 font-bold'>Theme Configuration</h3>
          <div className='bg-background/10 mb-4 grid gap-2 rounded-md p-2'>
            {Object.entries(themeConfig).map(([key, value]) => {
              if (typeof value !== 'string' || !value.startsWith('#'))
                return null;

              return (
                <div key={key} className='grid grid-cols-2 items-center gap-2'>
                  <span className='font-mono text-xs'>{key}:</span>
                  <div className='flex items-center gap-2'>
                    <div
                      className='size-5 rounded-sm border border-gray-300'
                      style={{ backgroundColor: value }}
                    />
                    <span className='font-mono text-xs'>{value}</span>
                  </div>
                </div>
              );
            })}
          </div>

          <h3 className='mb-2 mt-4 font-bold'>Applied CSS Variables</h3>
          <div className='bg-background/10 grid gap-2 rounded-md p-2'>
            {Object.entries(cssVars).map(([key, value]) => {
              if (!value.trim().startsWith('#')) return null;

              return (
                <div key={key} className='grid grid-cols-2 items-center gap-2'>
                  <span className='font-mono text-xs'>{key}:</span>
                  <div className='flex items-center gap-2'>
                    <div
                      className='size-5 rounded-sm border border-gray-300'
                      style={{ backgroundColor: value }}
                    />
                    <span className='font-mono text-xs'>{value}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
