import type { UserProfileToolDef } from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { memo } from 'react';

import { MessageContainer } from '@/components/message/components/MessageContainer';
import type { ToolProps } from '@/components/message/tools/AuthenticationTool';
import { UserProfile } from '@/components/message/tools/membershipTool/components/UserProfile';

export const UserProfileTool = memo(function UserProfileTool({
  toolCall,
  isFinished,
}: ToolProps) {
  // Return early if we don't have args or type yet
  if (!toolCall.args) {
    return null;
  }

  const args = toolCall.args as UserProfileToolDef;

  if (!args.content && isFinished) {
    return (
      <MessageContainer>
        <Trans>
          I am sorry, but I have not understood your question. Could you repeat
          it please?
        </Trans>
      </MessageContainer>
    );
  }

  return (
    <MessageContainer className='flex-col gap-2'>
      <UserProfile profile={{ ...args, content: args.content }} />
    </MessageContainer>
  );
});
