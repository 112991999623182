import { useQueryClient } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { MessageSquarePlus } from 'lucide-react';
import { memo, useState, useTransition, useEffect } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys, threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

type ResetConversationProps = {
  disabled?: boolean;
  onReset?: () => void;
};

export const ResetConversation = memo(function ResetConversation({
  disabled = false,
  onReset,
}: ResetConversationProps) {
  const { chatId } = useChatConfig();
  const {
    setThreadId,
    resetFAQLoop,
    pushRoute,
    resetConversation,
    setResetConversation,
  } = useBoundStore(state => ({
    setThreadId: state.setThreadId,
    resetFAQLoop: state.resetFAQLoop,
    pushRoute: state.pushRoute,
    resetConversation: state.resetConversation,
    setResetConversation: state.setResetConversation,
  }));

  const [_, startTransition] = useTransition();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (resetConversation) {
      const timer = setTimeout(() => {
        setResetConversation(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [resetConversation, setResetConversation]);

  /**
   * Create new thread to reset the conversation.
   */
  const queryClient = useQueryClient();

  /**
   * Reset conversation by creating new one and invalidating
   * the chat query storage.
   */
  const handleReset = async () => {
    onReset?.();
    startTransition(() => {
      setIsLoading(true);
    });

    try {
      // Push route to chat
      pushRoute('chat');

      // Create new thread id
      // FIXME: this will create a thread wout title
      const thread = await aiCoreApi.threads.create({
        chatId,
      });

      // Set thread id
      setThreadId(thread.id);

      // Reset FAQLoop
      resetFAQLoop();

      // Invalidate chat query storage.
      queryClient.invalidateQueries({
        queryKey: chatKeys.detail(chatId),
      });

      // Invalidate thread query storage.
      queryClient.invalidateQueries({
        queryKey: threadKeys.chatLists(chatId),
      });
    } catch (error) {
      console.error(error);
    } finally {
      startTransition(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    // <Tooltip.Provider>
    //   <Tooltip.Root open={resetConversation}>
    //     <Tooltip.Trigger asChild>
    <div className='relative'>
      <button
        type='button'
        className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
        onClick={handleReset}
        disabled={disabled}
      >
        <MessageSquarePlus
          className={cn('size-6', isLoading && 'animate-spin')}
          strokeWidth={1.5}
        />
      </button>
    </div>
    //     </Tooltip.Trigger>
    //     <Tooltip.Portal container={getPortalContainer()}>
    //       <Tooltip.Content
    //         className='z-50 overflow-hidden rounded-radius border border-popover-border bg-popover px-3 py-1.5 text-sm text-popover-fg shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'
    //         sideOffset={5}
    //       >
    //         <p className='max-w-[200px]'>
    //           <Trans>
    //             Is this a new topic or are you not satisfied with the answer?
    //             Click on this button and try again.
    //           </Trans>
    //         </p>
    //       </Tooltip.Content>
    //     </Tooltip.Portal>
    //   </Tooltip.Root>
    // </Tooltip.Provider>
  );
});
