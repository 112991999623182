import type { ChatbotConfig, ChatThemeConfig } from '@kanbu/schema';
import { THEME_PRESETS, pascalToKebab } from '@kanbu/shared';
import { useQuery } from '@tanstack/react-query';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';

/**
 * Maps theme property names to CSS variable names
 * Handles special cases like backgroundSecondary → background-secondary
 */
function themePropToCssVar(propName: string): string {
  // Special case mappings
  const specialCases: Record<string, string> = {
    primaryFg: 'primary-fg',
    secondaryFg: 'secondary-fg',
    backgroundSecondary: 'background-secondary',
  };

  // Check if it's a special case
  if (propName in specialCases) {
    return `--form-factory-${specialCases[propName]}`;
  }

  // Default case: convert camelCase to kebab-case
  return `--form-factory-${pascalToKebab(propName)}`;
}

export interface ChatConfig {
  chatId: string;
  model?: string;
  agentName: string;
  embeddingsVersion?: number;
  chatbotConfig: ChatbotConfig;
  themeConfig: ChatThemeConfig;
}

export const ChatConfigContext = createContext<ChatConfig | null>(null);

interface ChatConfigProps extends PropsWithChildren {
  chatId: string;
}

/**
 * Applies default theme values for any missing properties
 * Always uses the dark theme as default
 */
function applyDefaultThemeValues(
  themeConfig: ChatThemeConfig,
): ChatThemeConfig {
  // Use dark theme as default
  const defaultPreset = THEME_PRESETS.dark;

  // Create a new theme object with defaults for any missing properties
  return {
    primary: themeConfig.primary ?? defaultPreset.primary,
    primaryFg: themeConfig.primaryFg ?? defaultPreset.primaryFg,
    secondary: themeConfig.secondary ?? defaultPreset.secondary,
    secondaryFg: themeConfig.secondaryFg ?? defaultPreset.secondaryFg,
    background: themeConfig.background ?? defaultPreset.background,
    backgroundSecondary:
      themeConfig.backgroundSecondary ?? defaultPreset.backgroundSecondary,
    foreground: themeConfig.foreground ?? defaultPreset.foreground,
    placeholder: themeConfig.placeholder ?? defaultPreset.placeholder,
    logo: themeConfig.logo ?? null,
    logoBubble: themeConfig.logoBubble ?? null,
  };
}

/**
 * Fetches config from the server and provides it to the app.
 * Returns no children until the config is loaded.
 */
export function ChatConfigProvider({ chatId, children }: ChatConfigProps) {
  const [config, setConfig] = useState<ChatConfig>();
  const { data } = useQuery({
    queryKey: chatKeys.config(chatId),
    queryFn: async () => aiCoreApi.chat.config({ chatId }),
    enabled: !!chatId,
    staleTime: Number.POSITIVE_INFINITY,
  });

  /**
   * Create chat config state when the data are loaded.
   */
  useEffect(() => {
    if (!data) {
      setConfig(undefined);

      return;
    }

    // Apply default theme values for any missing properties
    const enhancedThemeConfig = applyDefaultThemeValues(data.themeConfig ?? {});

    const newConfig: ChatConfig = {
      chatId,
      model: undefined,
      embeddingsVersion: undefined,
      agentName: data.agentName,
      chatbotConfig: data.chatbotConfig,
      themeConfig: enhancedThemeConfig,
    };

    /**
     * Find chat root element, web component selector is
     * used in production, fallback to data attribute in dev mode.
     */
    const chatRoot =
      document.querySelector<HTMLElement>(
        `form-factory-chatbot[chat="${newConfig.chatId}"]`,
      ) ??
      document.querySelector<HTMLElement>(
        `form-factory-chatbot[data-chat="${newConfig.chatId}"]`,
      ) ??
      document.querySelector<HTMLElement>(
        `[data-kanbu-chat="${newConfig.chatId}"]`,
      ) ??
      document.querySelector<HTMLElement>('.form-factory-chatbot-app');

    // Set CSS variable overrides from config
    if (chatRoot) {
      try {
        Object.entries(newConfig.themeConfig).forEach(([key, value]) => {
          if (value) {
            chatRoot.style.setProperty(
              themePropToCssVar(key),
              value.toString(),
            );
          }
        });

        // Add a data attribute to indicate theme is applied
        chatRoot.dataset.themeApplied = 'true';
      } catch (error) {
        console.error('Failed to apply theme:', error);
      }
    } else {
      console.warn('Could not find chat root element to apply theme');
    }

    setConfig(newConfig);
  }, [chatId, data]);

  // Don't render anything until the config is loaded
  if (!config) {
    return null;
  }

  return (
    <ChatConfigContext.Provider value={config}>
      {children}
    </ChatConfigContext.Provider>
  );
}

/**
 * Hook for accessing chat config.
 */
export function useChatConfig() {
  const context = useContext(ChatConfigContext);

  if (!context) {
    throw new Error('Missing ChatConfigContext.Provider in the tree');
  }

  return context;
}
