import { Trans } from '@lingui/react/macro';
import { LogIn } from 'lucide-react';
import { memo, type MouseEvent } from 'react';

interface SignInProps {
  disabled?: boolean;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SignInButton = memo(function SignIn({
  onSubmit,
  disabled = false,
}: SignInProps) {
  return (
    <button
      type='button'
      className=' flex flex-nowrap items-center gap-2 truncate rounded-lg bg-gray-50/20 p-1 px-2 text-secondary-fg transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
      onClick={onSubmit}
      disabled={disabled}
    >
      <LogIn className='hidden size-4 sm:inline' />
      <span className='text-sm'>
        <Trans>Sign in</Trans>
      </span>
    </button>
  );
});
