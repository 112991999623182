import type {
  EfIntCreateMembershipDTO,
  WithClubId,
} from '@kanbu/schema/contracts';
import { Trans, useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { useState } from 'react';

import { getLocationName } from '@/constants/Locations';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { getPeriodTypeName } from './NewMembership';
import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import { Stack } from '../stack/Stack';

interface RegistrationMemberData {
  clubId: string;
  membershipId: number;
  date: string;
  selectedConsentIds: number[];
  description?: string;
  installmentsNumber: number;
  name: string;
  price: number;
  periodTime: number;
  periodType: number;
  discountCoupon?: string;
}

export function NewMemberShipRecap() {
  const { t } = useLingui();
  const { pushRoute, popRoute, user } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
    user: state.user,
  }));

  const { chatId } = useChatConfig();
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [registrationMemberData, setRegistrationMemberData] = useBoundStore(
    state => [
      state.registrationMemberData as RegistrationMemberData,
      state.setRegistrationMemberData,
    ],
  );

  const mutation = useMutation({
    mutationFn: async (params: WithClubId<EfIntCreateMembershipDTO>) => {
      return aiCoreApi.eFitness.memberships.create({
        chatId: params.chatId,
        clubId: params.clubId,
        membershipDefinitionId: params.membershipDefinitionId,
        userInfo: params.userInfo,
        agreementFrom: params.agreementFrom,
        selectedConsentIds: params.selectedConsentIds,
        selectedServiceIds: params.selectedServiceIds,
      });
    },
  });

  const createMemberShip = async () => {
    resetErrors();

    try {
      const data = await mutation.mutateAsync({
        chatId,
        clubId: registrationMemberData.clubId,
        membershipDefinitionId: registrationMemberData.membershipId,
        agreementFrom: registrationMemberData.date,
        selectedConsentIds: registrationMemberData.selectedConsentIds,
        selectedServiceIds: [],
        discountCoupon: registrationMemberData.discountCoupon,
        userInfo: {
          id: user!.externalId!,
          name: `${user!.firstName} ${user!.lastName}`,
          email: user!.email!,
          club: getLocationName(Number(registrationMemberData.clubId)),
          membershipName: registrationMemberData.name,
        },
      });

      setRegistrationMemberData({
        ...registrationMemberData,
        invoiceID: data.installmentIdsForPayment,
      });
      pushRoute('membershipPayment');
    } catch (error) {
      handleError(error);
    }
  };

  const descriptionItems = registrationMemberData?.description
    ? registrationMemberData.description.split('||')
    : [];

  return (
    <Stack title={t`Summary`} onBack={() => popRoute()}>
      <EfErrorAlert errors={errors} />
      <div className='space-y-4 rounded-lg bg-primary p-6'>
        <div className='grid gap-4'>
          <div className='space-y-2'>
            <div className='flex items-baseline justify-between'>
              <span className='text-gray-400'>
                <Trans>Club</Trans>
              </span>
              <strong className='font-medium'>
                {getLocationName(Number(registrationMemberData?.clubId))}
              </strong>
            </div>

            <div className='flex items-baseline justify-between'>
              <span className='text-gray-400'>
                <Trans>Membership</Trans>
              </span>
              <strong className='font-medium'>
                {registrationMemberData?.name}
              </strong>
            </div>

            <div className='flex items-baseline justify-between'>
              <span className='text-gray-400'>
                <Trans>Date</Trans>
              </span>
              <strong className='font-medium'>
                {registrationMemberData?.date}
              </strong>
            </div>
          </div>

          <div className='space-y-2'>
            <div className='flex items-baseline justify-between'>
              <span className='text-gray-400'>
                <Trans>Price</Trans>
              </span>{' '}
              <strong className='font-medium text-green-500'>
                {registrationMemberData?.price} Kč
                {registrationMemberData?.installmentsNumber === 1
                  ? ''
                  : registrationMemberData?.installmentsNumber === 2 ||
                      registrationMemberData?.installmentsNumber === 12
                    ? '/ ' + <Trans>Monthly</Trans>
                    : ''}
              </strong>
            </div>

            <div className='flex items-baseline justify-between'>
              <span className='text-gray-400'>
                <Trans>Contract duration</Trans>
              </span>
              <strong className='font-medium'>
                {registrationMemberData?.periodTime}{' '}
                {getPeriodTypeName(
                  registrationMemberData?.periodType,
                  registrationMemberData?.periodTime,
                  'cz',
                ) || <Trans>Indefinite period</Trans>}
              </strong>
            </div>
          </div>
        </div>

        {registrationMemberData?.description && (
          <div className='mt-6'>
            <h3 className='mb-2 text-gray-400'>
              <Trans>Description</Trans>
            </h3>
            <ul className='list-disc space-y-1 pl-5'>
              {descriptionItems.map((item, index) => (
                <li key={index} className='text-primary-fg'>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}

        <ConfirmDialog
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          onConfirm={createMemberShip}
        />

        <Button
          size='lg'
          type='submit'
          variant='success'
          className='mt-6 w-full'
          onClick={() => setIsDialogOpen(true)}
          disabled={mutation.isPending}
        >
          <Trans>Create membership</Trans>
        </Button>
      </div>
    </Stack>
  );
}
