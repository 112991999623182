import {
  PauseMembershipReasons,
  MembershipIsFreezableReason,
} from '@kanbu/schema/enums';
import { Trans, useLingui } from '@lingui/react/macro';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { TextArea, type TypedFormState } from '@utima/ui-informed';
import type { FormState } from 'informed';
import { useState } from 'react';

import { EfErrorAlert } from '@/components/alerts/EfErrorAlert';
import MultipleStepForm from '@/components/multistepForm/MultipleStepForm';
import StepForm from '@/components/multistepForm/StepForm';
import { NativeFormSelect } from '@/components/nativeFormSelect/NativeFormSelect';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { Stack } from '../stack/Stack';

type FormValues = {
  reason: PauseMembershipReasons;
  duration?: string;
  freezeHealthReason?: File;
  note?: string;
};

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.addEventListener('error', error => reject(error));
  });

export function PauseMembership() {
  const { t } = useLingui();
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [reason, setReason] = useState<PauseMembershipReasons | undefined>(
    undefined,
  );

  const { chatId } = useChatConfig();

  const { mutateAsync } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const fileBase64 = file ? await toBase64(file) : undefined;

      return aiCoreApi.eFitness.pauseMembership({
        chatId,
        reason: formState.values.reason,
        monthDuration: formState.values.duration || '1',
        note: formState.values.note,
        file: fileBase64,
      });
    },
  });

  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  // TODO: This is extremely awful, but I have few minutes only to handle error enums
  const getErrorLabel = (error: string) => {
    switch (error) {
      case MembershipIsFreezableReason.INVALID:
        return t`Membership is not freezable`;
      case MembershipIsFreezableReason.HAS_NOT_STARTED:
        return t`Your membership has not started yet`;
      case MembershipIsFreezableReason.EXPIRED:
        return t`Your membership has expired`;
      case MembershipIsFreezableReason.ALREADY_FROZEN:
        return t`Your membership is already frozen`;
      case MembershipIsFreezableReason.THREE_MONTHS_MINIMUM:
        return t`You need to have at least 3 months of active membership before you can freeze it`;

      default:
        return error;
    }
  };

  // Assuming this is part of a form with a controlled input
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(() => (event.target.files ? event.target.files[0] : undefined));
  };

  // TODO: We should expand on the multiform and stepform component and add a way to validate the form
  //       Both components are build on top of Informed, so we need "only" to understand
  //       when Informed is doing validations and how to hook into that.
  const validateForm = (formState: FormState) => {
    if (formState.values.reason === PauseMembershipReasons.Health && !file) {
      setFileError(t`Please upload a file`);
      throw new Error('Missing a file!');
    }
  };

  const handleSubmit = async (formState: TypedFormState<FormValues>) => {
    resetErrors();
    setFileError(undefined);

    try {
      await mutateAsync(formState);
      setShowConfirmation(true);
    } catch (error) {
      setShowConfirmation(false);
      await handleError(error);
    }
  };

  return (
    <Stack title={t`Pause membership`} onBack={() => pushRoute('chat')}>
      <EfErrorAlert
        // TODO: This is extremely awful, but I have few minutes only to handle error enums
        errors={errors.map(er => ({
          ...er,
          message: getErrorLabel(er.message),
        }))}
      />
      <MultipleStepForm onSubmit={handleSubmit}>
        <StepForm
          onSubmit={validateForm}
          className='flex flex-col gap-5'
          validateOn='change-submit'
        >
          <NativeFormSelect
            onChange={e => setReason(e.value as PauseMembershipReasons)}
            name='reason'
            required
            label={t`Reason for freeze`}
          >
            <option
              key={PauseMembershipReasons.Personal}
              value={PauseMembershipReasons.Personal}
            >
              <Trans>Personal</Trans>
            </option>
            <option
              key={PauseMembershipReasons.Health}
              value={PauseMembershipReasons.Health}
            >
              <Trans>Health</Trans>
            </option>
          </NativeFormSelect>
          {reason === PauseMembershipReasons.Health && (
            <>
              <NativeFormSelect
                name='duration'
                required
                label={t`Duration of the freeze`}
              >
                <option key='1' value='1'>
                  <Trans>1 month</Trans>
                </option>
                <option key='2' value='2'>
                  <Trans>2 months</Trans>
                </option>
              </NativeFormSelect>
              <div className='flex flex-col gap-2'>
                <label
                  className='inline-flex items-center text-sm font-medium leading-5 text-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                  htmlFor='freezeHealthReason'
                >
                  <Trans>Confirmation from a doctor</Trans>
                </label>
                <input
                  id='freezeHealthReason'
                  type='file'
                  name='freezeHealthReason'
                  accept='image/png, image/jpeg, application/pdf'
                  onChange={handleFileChange}
                  // label={t`Please specify the health reason`}
                />
                {!!fileError && (
                  <span className='text-sm text-red-500'>{fileError}</span>
                )}
              </div>
            </>
          )}
          <TextArea name='note' label={t`Note`} />
          <Button type='submit' size='lg'>
            <Trans>Request a pause</Trans>
          </Button>
        </StepForm>
        <StepForm
          className='flex flex-col gap-5 text-center'
          validateOn='change-submit'
        >
          {!showConfirmation && (
            <>
              <p>
                <Trans>Are you certain?</Trans>
              </p>
              <div className='flex flex-row items-center justify-center gap-2 align-middle'>
                <Button
                  type='button'
                  size='lg'
                  onClick={() => pushRoute('chat')}
                >
                  <Trans>No, take me back</Trans>
                </Button>
                <Button type='submit' className='bg-secondary' size='lg'>
                  <Trans>Request a pause</Trans>
                </Button>
              </div>
            </>
          )}
          {showConfirmation && (
            <div className='flex flex-col items-center justify-center gap-2 align-middle'>
              <Trans>
                Thank you for the request to pause your membership. Please wait
                for confirmation email from our team.
              </Trans>
              <Button type='button' size='lg' onClick={() => pushRoute('chat')}>
                <Trans>Back to chat</Trans>
              </Button>
            </div>
          )}
        </StepForm>
      </MultipleStepForm>
    </Stack>
  );
}
