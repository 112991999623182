import type { StructuredToolCall } from '@kanbu/schema';
import type {
  AuthenticationToolDef,
  MembershipToolDef,
} from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { memo } from 'react';
import type { PartialDeep } from 'type-fest';

import { MessageContainer } from '@/components/message/components/MessageContainer';
import { MessageMarkdown } from '@/components/message/components/MessageMarkdown';
import { MembershipLogin } from '@/components/message/tools/membershipTool/components/MembershipLogin';

import { MembershipRegistration } from './membershipTool/components/MembershipRegistration';

export type ToolProps = {
  toolCall: StructuredToolCall<PartialDeep<MembershipToolDef>>;
  isFinished?: boolean;
};

export const AuthenticationTool = memo(function AuthenticationTool({
  toolCall,
  isFinished,
}: ToolProps) {
  // Return early if we don't have args or type yet
  if (!toolCall.args) {
    return null;
  }

  const args = toolCall.args as AuthenticationToolDef;

  if (!args.content && isFinished) {
    return (
      <MessageContainer>
        <Trans>
          I am sorry, but I have not understood your question. Could you repeat
          it please?
        </Trans>
      </MessageContainer>
    );
  }

  if (args.shouldLogin) {
    return (
      <MessageContainer className='flex-col'>
        <MembershipLogin login={{ ...args, content: args.content }} />
      </MessageContainer>
    );
  }

  if (args.shouldRegister) {
    return (
      <MessageContainer className='flex-col'>
        <MembershipRegistration
          registration={{ ...args, content: args.content }}
        />
      </MessageContainer>
    );
  }

  return (
    <MessageContainer className='flex-col gap-2'>
      {args.content && <MessageMarkdown>{args.content}</MessageMarkdown>}
    </MessageContainer>
  );
});
