import { Trans } from '@lingui/react/macro';
import { UserPlus } from 'lucide-react';
import { memo } from 'react';

import { useNavigate } from '@/hooks/useNavigate';

import { MessageMarkdown } from '../../../components/MessageMarkdown';

type MembershipRegistrationProps = {
  registration: {
    content: string;
    shouldRegister: boolean;
  };
};

export const MembershipRegistration = memo(function MembershipRegistration({
  registration,
}: MembershipRegistrationProps) {
  const { navigate } = useNavigate();

  const { content, shouldRegister } = registration;

  return (
    <>
      <MessageMarkdown>{content}</MessageMarkdown>
      {shouldRegister && (
        <button
          type='button'
          onClick={() => navigate('memberRegistration')}
          className='my-3 flex flex-row items-center justify-center gap-2 rounded-lg bg-success/20 px-3 py-2.5 text-success shadow drop-shadow transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100'
        >
          <UserPlus className='size-5' />
          <Trans>Get membership</Trans>
        </button>
      )}
    </>
  );
});
