import { plural, t } from '@lingui/core/macro';
import { cn } from '@utima/ui';
import {
  memo,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
} from 'react';

export interface TimeAgoProps extends ComponentPropsWithoutRef<'div'> {
  date: string;
  position?: 'left' | 'right';
}

/**
 * Calculate the time difference between the
 * provided time and the current time. It also
 * updates the time every minute.
 */
function useTimeAgo(date: string, updateInterval = 60000) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [updateInterval]);

  const timeDifferenceInSeconds = Math.floor(
    (Number(currentTime) - Number(new Date(date).getTime())) / 1000,
  );

  if (timeDifferenceInSeconds < 60) {
    return t`Sent just now`;
  }

  let time = '';

  if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);

    time = plural(minutes, {
      one: `# minute`,
      other: `# minutes`,
    });
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);

    time = plural(hours, {
      one: `# hour`,
      other: `# hours`,
    });
  } else {
    const days = Math.floor(timeDifferenceInSeconds / 86400);

    time = plural(days, {
      one: `# day`,
      other: `# days`,
    });
  }

  return t`Sent ${time} ago`;
}

export const TimeAgo = memo(function TimeAgo({
  date,
  position,
  className,
  ...restProps
}: TimeAgoProps) {
  const timeAgo = useTimeAgo(date, 60000);

  return (
    <div
      className={cn(
        'pt-2 text-xs text-foreground',
        {
          ['text-right']: position === 'right',
          ['text-left']: position === 'left',
        },
        className,
      )}
      {...restProps}
    >
      {timeAgo}
    </div>
  );
});
