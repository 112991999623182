import { DateFormat, formatUtils } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { BadgePlus } from 'lucide-react';
import { memo } from 'react';

import { Image } from '@/components/image/Image';
import { useNavigate } from '@/hooks/useNavigate';
import { useProvider } from '@/hooks/useProvider';
import { useUser } from '@/hooks/useUser';

import { MessageMarkdown } from '../../../components/MessageMarkdown';

type MembershipStatusProps = {
  status: {
    content: string;
    activeMembership?: string;
    activeFrom?: string;
    activeTo?: string;
    isExpired: boolean;
    showNewMembershipButton: boolean;
    showPauseMembershipButton?: boolean;
    isFinished?: boolean;
  };
};

export const MembershipStatus = memo(function MembershipStatus({
  status,
}: MembershipStatusProps) {
  const { navigate } = useNavigate();
  const { isEfitnessAuthenticated } = useUser();
  const { logo } = useProvider();

  const {
    content,
    activeMembership,
    activeFrom,
    activeTo,
    isExpired,
    showNewMembershipButton,
    showPauseMembershipButton,
  } = status;

  return (
    <>
      <div
        className={`mb-4 min-w-64 rounded-lg ${isExpired ? 'bg-gradient-to-br from-orange-400 to-orange-900' : 'bg-gradient-to-br from-rose-400 to-rose-900'} shadow-lg drop-shadow-md duration-500 animate-in fade-in-0 zoom-in-95`}
      >
        <div className='p-4'>
          <div className='relative'>
            <h4 className='mb-4 min-h-6 text-left text-lg font-bold text-rose-950'>
              {activeMembership}
            </h4>
            <Image className='absolute right-0 top-1 h-3' src={logo} />
          </div>
          <div className='space-y-2 text-xs'>
            <div className='flex items-center justify-between border-b border-rose-900/30 pb-2'>
              <strong className='text-white'>
                <Trans>Status</Trans>
              </strong>
              <span className='ml-2 rounded-full bg-orange-200/20 px-2 py-0.5 text-xs text-orange-100'>
                {isExpired ? <Trans>Expired</Trans> : <Trans>Active</Trans>}
              </span>
            </div>
            <div className='flex items-center justify-between border-b border-rose-900/30 pb-2'>
              <strong className='text-white'>
                <Trans>Membership start</Trans>
              </strong>
              <span className='font-medium text-primary-fg'>
                {formatUtils.date(activeFrom, DateFormat.DateNumeric)}
              </span>
            </div>
            <div className='flex items-center justify-between'>
              <strong className='text-white'>
                <Trans>Membership end</Trans>
              </strong>
              <span className='font-medium text-primary-fg'>
                {formatUtils.date(activeTo, DateFormat.DateNumeric)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <MessageMarkdown>{content}</MessageMarkdown>

      {showNewMembershipButton && isEfitnessAuthenticated() && (
        <button
          type='button'
          onClick={() => navigate('newMembership')}
          className='my-3 flex shrink-0 flex-row items-center justify-center gap-1 rounded-md bg-success/20 px-3 py-2.5 text-success transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100'
        >
          <BadgePlus className='size-5' />
          <Trans>New membership</Trans>
        </button>
      )}

      {showPauseMembershipButton && isEfitnessAuthenticated() && (
        <div className='gap-y-2'>
          <p>
            <Trans>
              This is a temporary suspension of membership according to
              our&nbsp;
              <a
                href='https://www.formfactory.cz/wp-content/uploads/2025/01/2022-09_vop_ff.pdf'
                target='_blank'
                rel='noreferrer'
                className='text-secondary'
              >
                terms and conditions
              </a>
              . If you wish to cancel the membership, please contact the
              reception of your club
            </Trans>
          </p>
          <button
            type='button'
            onClick={() => navigate('pauseMembership')}
            className='my-3 flex shrink-0 flex-row items-center justify-center gap-1 rounded-md bg-warning/20 px-3 py-2.5 text-warning transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100'
          >
            <BadgePlus className='size-5' />
            <Trans>Pause membership</Trans>
          </button>
        </div>
      )}
    </>
  );
});
